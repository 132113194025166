<template>
  <div class="body-1">
    <div v-if="loadedAgency != undefined">
      <v-card>
        <v-card-title>{{loadedAgency.agencyId}}</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-container>
              <v-layout wrap justify-space-between>
                <v-flex md4>
                  <v-text-field v-model="loadedAgency.agencyId" label="Agency Id" required></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-text-field v-model="loadedAgency.description" label="Description" required></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-text-field v-model="loadedAgency.token" label="API Key" required></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-space-between>
                <v-flex md4>
                  <v-text-field v-model="loadedAgency.agencyCity" label="City" required></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-text-field v-model="loadedAgency.agencyState" label="State" required></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-text-field v-model="loadedAgency.agencyCounty" label="County" required></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-alert type="error" :value="errorSavingAgency != ''">{{errorSavingAgency}}</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="save">Save</v-btn>
          <v-btn @click="reset" text>Reset Changes</v-btn>
          <v-btn @click="cancel" text>Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <p v-else>Loading...</p>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  computed: mapState({
    loadedAgency: state => state.admin.loadedAgency,
    isSavingAgency: state => state.admin.isSavingAgency,
    errorSavingAgency: state => state.admin.errorSavingAgency,
    forceRedirect: state => state.admin.hasSavedAgencyRedirect
  }),
  methods: {
    ...mapActions(["loadAdminAgency", "saveAdminAgency"]),
    save() {
      this.saveAdminAgency({
        agencyId: this.$route.params.agencyId,
        loadedAgency: this.loadedAgency
      });
    },
    cancel() {
      this.$router.push({ name: "adminAgencyList" });
    },
    reset() {
      this.loadAdminAgency({ agencyId: this.$route.params.agencyId });
    }
  },
  created() {
    this.loadAdminAgency({ agencyId: this.$route.params.agencyId });
  },
  watch: {
    forceRedirect: () => {
      this.$router.push({ name: "adminAgencyList" });
    }
  }
};
</script>

